import React from 'react';
import {ScrollMenu} from 'react-horizontal-scrolling-menu';
import Slider from "react-slick";
import {isMobile} from "@/Helpers/Function";
import isEmpty from "is-empty";
import LinkLoad from "@/Layouts/LinkLoad";
import Svg from "@/Pages/All/Svg";

export default function MainSlider({...props}) {
    const {config = {}, homeCarouselData} = props;
    const {informationList = []} = config;

    return (
        <div className={`section__slider`}>
            <div className={`container`}>
                <div className={`slider`}>
                    <Slider {...settings}>
                        {homeCarouselData.map(({id, imgPath, imgMobilePath, link, label}) => {
                            if (!isEmpty(link)) {
                                return <LinkLoad key={id}
                                                 onClick={() => props.stores.filterStore.handleClickLink()}
                                                 href={`${link}`}
                                                 className={`slider__item`}
                                >
                                    <img src={isMobile() ?
                                        imgMobilePath.replace('jpg', 'webp') :
                                        imgPath.replace('jpg', 'webp')}
                                         rel={`preload`}
                                         as={`image`}
                                         alt={label}
                                         title={label}
                                         width={isMobile() ? "400" : "1330"}
                                         height={isMobile() ? "400" : "624"}
                                    />
                                </LinkLoad>;
                            } else {
                                return <div key={id}
                                            className={`slider__item`}>
                                    <img src={isMobile() ?
                                        imgMobilePath.replace('jpg', 'webp') :
                                        imgPath.replace('jpg', 'webp')}
                                         rel={`preload`}
                                         as={`image`}
                                         alt={label}
                                         title={label}
                                         width={isMobile() ? "400" : "1330"}
                                         height={isMobile() ? "400" : "624"}
                                    />
                                </div>;
                            }
                        })}
                    </Slider>
                </div>
                <div className={`information`}>
                    <div className={`information__wrapper`}>
                        <ScrollMenu
                            alignCenter={false}
                            wheel={false}
                            itemClass={`information__item`}
                            scrollContainerClassName={`mainSlider`}
                        >
                            {informationList.map(({id, svgClassName, svgName, text, link, textLink}) => {
                                return <div key={id} className={`information__wrapper-chip`}>
                                    <Svg id={svgName} className={svgClassName} />
                                    <div className={`desc`}>
                                        <div>{text}&nbsp;</div>
                                        <LinkLoad href={link} className={`desc__link`}>
                                            {textLink}
                                        </LinkLoad>
                                    </div>
                                </div>
                            })}
                        </ScrollMenu>
                    </div>
                </div>
            </div>
        </div>
    );
}

const AddAiOutlineArrowLeft = (props) => (
    <Svg id={`arrows-left`} className={`MuiSvgIcon-root arrows arrows__left`} onClick={props.onClick} />
);

const AddAiOutlineArrowRight = (props) => (
    <Svg id={`arrows-right`} className={`MuiSvgIcon-root arrows arrows__right`} onClick={props.onClick} />
);

const settings = {
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 1000,
    fade: false,
    dots: true,
    dotsClass: 'slick-main-dots',
    arrows: true,
    infinite: true,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <AddAiOutlineArrowLeft />,
    nextArrow: <AddAiOutlineArrowRight />,
    responsive: [
        {
            "breakpoint": 992,
            "settings": {
                "arrows": false
            }
        },
        {
            "breakpoint": 767,
            "settings": {
                "arrows": false
            }
        }
    ],
    appendDots: dots => (
        <ul> {dots} </ul>
    ),
    customPaging: () => (
        <span></span>
    )
};
